.main-body {
  background-color: #fff;
  padding: 30px 20px 50px 20px;
  height: 100vh;
  overflow: auto;
}
.main-patientinfo {
  background-color: #ecf5ff;
  border-radius: 10px;
  padding: 40px 20px 40px 20px;
}
.main-patientinfo h1 {
  font-size: 30px;
  margin-bottom: 50px;
  font-weight: 600;
  margin-left: 30px;
}

.personal-info .form-floating > .form-control {
  border: none;
  background-color: #f9f9f9;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 12px;
}
.form-control {
  font-weight: 12px;
}

.form-floating > label {
  font-size: 13px;
  padding-top: 0.7rem;
}
.personal-info .form-floating > .form-select {
  padding-top: 1.3rem;
  padding-bottom: 0.225rem;
  border: none;
  background-color: #f9f9f9;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  font-size: 12px;
}

.personal-info {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  height: 100%;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
}

.personal-info h3 {
  margin-bottom: 30px;
  font-size: 14px;
}

.form-two .btn-previous {
  border: none;
  background-color: inherit;
}

.form-two .btn-previous a {
  color: #0031df;
}

.select-location label {
  padding-bottom: 3px;
}
.select-location {
  margin-bottom: 14px;
}

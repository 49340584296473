.password-forms {
    padding: 20px;
    background-color: #ecf5ff;
    border-radius: 10px;

}

.right-sec {
    margin-left: 20px;
}
.button {
    
    font-weight: bold;
    cursor: pointer;
    color: #f9f9f9;
    background-color: rgb(70, 59, 231);
}

.button:hover {
    font-weight: bold;
    color: rgb(42, 13, 146);
    background-color: rgb(142, 142, 247);
}
.password-forms h5 {
    font-weight: 600;
}

.form-floating>.form-select {
    padding-top: .9rem;
    padding-bottom: 0;
    font-size: 12px;
}

.form-floating>.form-select {
    height: 40px !important;
    line-height: 1.25;
}

.form-floating>.form-control {
    height: 40px;
    line-height: 1.5;
    font-size: 12px;
}

.personal-info .form-floating>.form-control {
    border: none;
    background-color: #f9f9f9;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 12px;
}

.form-floating>label {
    font-size: 13px;
    padding-top: 0.7rem;
}

.personal-info .form-floating>.form-select {
    padding-top: 1.3rem;
    padding-bottom: 0.225rem;
    border: none;
    background-color: #f9f9f9;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;
    font-size: 12px;
}

.insurance-search .form-floating {
    width: 92%;
    border-radius: 0px 0px 10px 10px;
}

.login-inputs .form-control {
    background-color: #ececec;
    border: none;
    margin-bottom: 40px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.login-inputs .form-control:focus {
    box-shadow: none
}

.active-medications .input-group>.form-control,
.medical-history .input-group .form-control,
.problem-search-feild .input-group .form-control {
    padding: 10px;
    border-radius: 10px;
}

.demo-personal-info .form-control {
    height: 65px;
    border: none;
    background-color: #fbfbfb;
    box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19)
}

.insurance-search .form-control {
    border: 1px solid #686868;
}

.map-content .form-control {
    border: none;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;
}

.map-content h5 {
    margin: 0;
}

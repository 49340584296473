.patient-list{
    padding: 30px;
    height: 80vh;
    overflow: auto;

}
.login-inputs .input-group-text{
    height: 40px;
    border: none;
    background-color: #2490eb;
    padding: 0px 20px 0px 20px;
    font-size: 20px;
    z-index: 9999;
    
}
.input-search{
    width: 20%;
}
.form-control{
    position: relative;
}
.patient-info th{
    background-color:#14457b;
    color: #fff;
        }
    
        .patient-info tr:nth-child(even) {background-color: #ecf5ff;}
    
        .patient-info tr:nth-child(odd) {background-color: #c8e3ff;}

        table {
            border: 1px #a39485 solid;
            font-size: .9em;
            box-shadow: 0 2px 5px rgba(0,0,0,.25);
            width: 100%;
            border-collapse: collapse;
            border-radius: 5px;
            overflow: hidden;
          }
          
          th {
            text-align: left;
            font-size: 14px;
          }
            
          thead {
            font-weight: bold;
            
          }
            
           td, th {
            padding: 1em .5em;
            vertical-align: middle;
          }
            
           td {
            border-bottom: 1px solid rgba(0,0,0,.1);
            font-size: 12px;
          }
          
          a {
            color: #73685d;
            font-size: 12px;
          }
            
           @media all and (max-width: 768px) {
              
            table, thead, tbody, th, td, tr {
              display: block;
            }
            
            th {
              text-align: right;
            }
            
            table {
              position: relative; 
              padding-bottom: 0;
              border: none;
              box-shadow: 0 0 10px rgba(0,0,0,.2);
            }
            
            thead {
              float: left;
              white-space: nowrap;
            }
            
            tbody {
              overflow-x: auto;
              overflow-y: hidden;
              position: relative;
              white-space: nowrap;
            }
            
            tr {
              display: inline-block;
              vertical-align: top;
            }
            
            
            }
        
            .user-img{
                width: 40px;
                height: 40px;
            }
        
            .patient-info th{
        background-color:#14457b;
        color: #fff;
            }
        
            .patient-info tr:nth-child(even) {background-color: #ecf5ff;}
        
            .patient-info tr:nth-child(odd) {background-color: #c8e3ff;}
        
            .modal-header{
                background-color: #307bc4;
                color: white;
            }
        
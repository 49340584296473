
body{ font-family: "Poppins", sans-serif;
    padding: 0; 
    margin: 0;
    background-color: #f3f1f1;   
    height: 100vh
}

.login-body{
    background-color: #7ebbfa30;
}
/* login-page-start */
.main-login{
padding: 100px;
}

.login-page-bg{
    width: 550px;
    border-radius: 10px;
}

.brand-logo{
    background-color: #1b538b;
    padding: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  
}

.form-control{
    position: relative;
}


.login-inputs{
    padding: 40px 40px 40px 40px;
    background-color: #fff;
    
}

.login-inputs .form-floating{
    width: 413px;
}


.login-inputs .form-control{
    background-color: #ececec;
    border: none;
    margin-bottom: 40px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
   
}

.login-inputs .form-control:focus{
    box-shadow:none
}
.login-inputs .input-group-text{
    height: 58px;
    border: none;
    background-color: #7ebbfa;
    padding: 0px 20px 0px 20px;
    font-size: 20px;
    z-index: 9999;
    
}

.btn-signin .btn-signin{
width: 170px;
height: 50px;
border-radius: 5px;
    background-color: #1b538b;
    color: white;
border: none;
font-weight: 500;
font-size: 18px;
}

.btn-forgot{
    background-color: #7ebbfa;
    padding: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 20px;

}

.btn-forgot .btn-primary{
    font-size: 20px;
    background-color: inherit;
    border: none;
    color:black;
    font-weight: 500;
}

/* login-page-end */

.header-sec{
    padding: 20px 20px 20px 30px;
    background-color: #1b538b;

}

.brand-logo-patient img{
    width: 50%;
}

.input-search{
    width: 20%;
}

.search-icon {
    position: absolute;
    top: 30%;
    right: 6%;
    font-size: 14px;
    z-index: 99999;
    color: #73685d;
}


.drop-arrow{

    position: absolute;
    top: 38%;
    right: 9%;
    font-size: 20px;
    z-index: 99999;
    color: black;

}

.facility-down .dropdown .btn-secondary{
padding: 10px;
    text-align: left;
    background-color: #fff;
    color: #73685d;
    width: 100%;
    font-size: 12px;
}

.facility-down .dropdown-menu{
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(0px, 68px);
    width: 100%;
    top: -8% !important;
}

.form-floating>.form-select {
    padding-top: 1.625rem;
    padding-bottom: .625rem;
    font-size: 12px;
}

.form-floating>.form-select {
    height: calc(3.1rem + 2px);
    line-height: 1.25;
}

.form-floating>.form-control{
    height: calc(3.1rem + 2px);
    line-height: 1.25;
}

.facility-down .dropdown{
    position: relative;
}
.facility-down   .dropdown-toggle::after {
        display: inline-block;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        position: absolute;
        right: 0px;
        top:12px;
        right: 7%;
        top: 44%;
        font-size: 18px;
    }

    .facility-down{
        width: 20%;
    }

.bell-icon{
    font-size: 14px;
    color: #fff;
}


.admin-name a{
    text-decoration: none;
    color: white;
    font-size: 14px;
}

.drop-icon{
    color: rgb(255, 255, 255);
    text-emphasis: none;
}

.patient-list{
    padding: 30px;

}
.search-icon-list{
    position: absolute;
    top: 36%;
    right: 9%;
    font-size: 18px;
    z-index: 999;
    color: black;
}


.input-search{
    height: 37px;
}

table {
    border: 1px #a39485 solid;
    font-size: .9em;
    box-shadow: 0 2px 5px rgba(0,0,0,.25);
    width: 100%;
    border-collapse: collapse;
    border-radius: 5px;
    overflow: hidden;
  }
  
  th {
    text-align: left;
    font-size: 14px;
  }
    
  thead {
    font-weight: bold;
    
  }
    
   td, th {
    padding: 1em .5em;
    vertical-align: middle;
  }
    
   td {
    border-bottom: 1px solid rgba(0,0,0,.1);
    font-size: 12px;
  }
  
  a {
    color: #73685d;
    font-size: 12px;
  }
    
   @media all and (max-width: 768px) {
      
    table, thead, tbody, th, td, tr {
      display: block;
    }
    
    th {
      text-align: right;
    }
    
    table {
      position: relative; 
      padding-bottom: 0;
      border: none;
      box-shadow: 0 0 10px rgba(0,0,0,.2);
    }
    
    thead {
      float: left;
      white-space: nowrap;
    }
    
    tbody {
      overflow-x: auto;
      overflow-y: hidden;
      position: relative;
      white-space: nowrap;
    }
    
    tr {
      display: inline-block;
      vertical-align: top;
    }
    
    
    }

    .user-img{
        width: 40px;
        height: 40px;
    }

    .patient-info th{
background-color: #1b538b;
color: #fff;
    }

    .patient-info tr:nth-child(even) {background-color: #ecf5ff;}

    .patient-info tr:nth-child(odd) {background-color: #c8e3ff;}

    .modal-header{
        background-color: #307bc4;
        color: white;
    }

    .modal-footer{
        background-color: #307bc4;
        color: white;
    }
    .modal-body{
padding: 0px;
    }
.btn-danger{
    background-color: white;
    color: #000;
border: none;
}
    /* patientinfo forms start */

    .main-body{
        background-color: #fff;
        padding: 30px 20px 50px 20px;
    }

    .main-patientinfo{
        background-color: #ecf5ff;
        border-radius: 10px;
        padding: 40px 20px 40px 20px;

    }
    .main-patientinfo h1{
        font-size: 30px;
        margin-bottom: 50px;
        font-weight: 600;
        margin-left: 30px;
    }

    .personal-info .form-floating>.form-control{
    border: none;
    background-color: #f9f9f9;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 12px;
}
.form-control{
    font-size: 12px;
}

.form-floating>label{
    font-size: 12px;
}
.personal-info .form-floating>.form-select{
    padding-top: 1.3rem;
    padding-bottom: 0.225rem;
    border: none;
    background-color: #f9f9f9;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    font-size: 12px;
}
   
    .personal-info{
        border: 1px solid #ced4da;
        padding: 15px;
        background-color: #fff;
        border-radius: 10px;
    }

    .personal-info h3{
        margin-bottom: 30px;
        font-size: 14px;
    }

    
    
   

  
    .form-two .btn-previous{
        border: none;
        background-color: inherit;
    }

    .form-two .btn-previous a{
color: #0031DF;
    }

    .select-location label{
        padding-bottom: 3px;
    }
    .select-location{
        margin-bottom: 14px;
    }
/* patientinfo forms end */
/* patient-detail start */
.header-sec-two{
    padding: 12px 20px 12px 20px;
    background-color: #1b538b;

}

.main-left{
    background-color: #ECF5FF;
    width: 300px;
    border-radius: 0px 30px 0px 0px;
}


.patient-detail-tabs .nav-tabs .nav-link.active{
    background-color: #1b538b;
    color: #fff;
    border-bottom: 2px solid #afafaf;}


li,a{
    font-size: 12px;
}
h2,h5,h3,h4,h6{
    font-size: 14px;
}
.patient-detail-tabs .nav-tabs .nav-link{
    color: black;
  
    font-size: 14px;
}

    .left-nav{
        width:300px;
         }

         .patient-data{
            background-color: #1b538b;
            padding: 40px 20px 40px 20px;
            border-radius: 0px 30px 0px 0px;
         }

         .user-detail p{
font-size: 12px;
margin-bottom: 5px;
color: white;
         }
        
         .user-icon li{
            list-style: none;
            padding: 0;
            margin-right: 20px;
         }
         .user-icon li a{
            text-decoration: none;
            color: #fff;
            font-size: 12px;
          
         }

         .user-icon i:hover{color: #ba2748;}
         .user-detail ul{
            margin: 0px;
            padding: 0px;
         }

         .user-detail h5{
            margin-bottom: 5px;
            color: #fff;
            font-size: 14px;
         }

.user-detail span a{
text-decoration: none;
color: #61FF00;
margin-bottom: 5px;
font-size: 12px;

}
ul,li,a{
    text-decoration: none;
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.user-bio li{

border-bottom: 1px solid #d7e2ed;
padding: 10px 0px 10px 20px ;
}
.user-bio li:hover a{
   cursor: inherit
}
.user-bio-links li span{
font-weight: 300;
color: black;
}

.user-bio-links li a{
    font-weight: 400;
    color: black;
    font-size: 12px;
}

.user-bio-dropdowns .btn-group{
    width: 100%;
}




.accordion{
    --bs-accordion-btn-icon:#fff;
}
.accordion-button {
    color: #ffffff;
    text-align: left;
    background-color: #1b538b; 
}
.accordion-button::after{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

/* right-sec-start */

.right-sec{
    margin-left: 20px;
}

.right-linkstabs{
    background-color: #1b538b;
    padding: 10px 25px 10px 10px;
    border-radius: 10px 0px 0px 0px;
}

.right-linkstabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border: none;
}

.right-linkstabs .dropdown{
    background-color: #e0eefc;
    border-radius: 10px;
    border: none;
}


.right-linkstabs .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color:inherit; 
    border-color: inherit !important;
    border: 1px inherit;
 border-bottom: 2px solid #fff;
 
}

.right-linkstabs .nav-tabs .nav-link{
    color: white;
 font-size: 14px;
 background-color: transparent;
   
}

.right-linkstabs .nav-tabs{
    border-bottom: none;
}

  

    .right-linkstabs .dropdown .btn-secondary{
        padding: 10px;
            text-align: left;
            background-color: #fff;
            color: #73685d;
            width: 100%;
            font-size: 12px;

        }
.dropdown-item{
font-size: 12px;
}
       
        .right-linkstabs .menu-bar{
            font-size: 27px;
            color: #fff;

        }

        .Demographics .nav-link{
            background-color: transparent;
        }
        .Demographics{
            padding: 15px 20px 15px 30px;
            background-color: #ecf5ff;
            margin-bottom: 5px;
            border-radius: 
            0px 0px 0px 10px;
        }

        .Demographics ul li a{
            font-size: 20px;
            color: black;
            font-weight: 600;
        }

        .basic-bg{
background-color: #1b538b;
padding: 10px;
color: #fff;
border-radius:
10px 10px 0px 0px;
        }

        .basic-bg h5{
            margin: 0;
            font-size: 14px;
            
        }

        .basic-info{
padding: 10px;
        }

        .demographics-cards{
              box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
background-color: #fff;
border-radius:
10px 10px 10px 10px;
height:100%

        }
        .Demographics ul li a{
            font-size: 14px;
        }
        .basic-info li{
margin-bottom: 5px;
        }


        .body-space-p{
            padding: 20px;
            background-color: #ecf5ff;
        }


        .basic-info li span{
            font-weight: 600;
            color: #5B5B5B;
            }
            
            .basic-info li a{
                font-weight: 300;
                color: #656565;
                font-size: 12px;
            }



            .btn-encounters-links {
                background-color: #1b538b;
                padding: 5px;
                border: none;
                color: #fff;
                border-radius: 5px;
                font-size: 12px;
            }

           




            .msg-link a{
                text-decoration: underline;
                font-size: 16px;
                color: #656565;
                font-size: 12px;
            }



            .medication-links{
                padding: 15px 30px 15px 30px;
                background-color: #ecf5ff;
                margin-bottom: 5px;
                border-radius: 
                0px 0px 0px 10px;
            }


            .medication-links .nav-item  a{
                font-size: 14px;
                color: black;
                font-weight: 600;
            }

            .medication-links  .input-group {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                align-items: stretch; 
                padding: 5px;
                border-radius: 10px;
            }


            .Medications-btn{
                width: 20%;
            }

            .btn-group .btn-danger{

            background-color: #1b538b;
            border: none;
            padding: 10px;
            border-radius: 10px;

            }
            .Medications-btn .dropdown-toggle {
                white-space: nowrap;
                background-color: #195085 !important;
            }

            .Medications-btn-add .dropdown-toggle {
                white-space: nowrap;
                background-color: #195085 !important;
            }

            .Medications-btn-add .btn-danger{
                font-size: 14px;
            }
            .Medications-btn-add .btn-danger{
                background-color: #307bc4;
                color: white;
            }
            .active-medications{

                padding:20px 10px 30px;
                background-color: #1b538b;
                border-radius: 
                10px 10px 0px 0px;
                color: #fff;
            }

            .active-medications h6{
                margin: 0;
                font-size: 14px;
            }

            .active-medications .input-group {
                background-color: #fff;
color: #000;
width: 30%;
border-radius: 10px;
            }

            .active-medications .input-group>.form-control{
                padding: 10px;
                border-radius: 10px;
                            }
            .medication-anchor a{
                color: #fff;
                text-decoration: underline ;
            }

           
            .medication-tables{
                background-color:#F6F6F6;
            }

            .medication-tables thead{
                background-color:#ECF5FF;
               color: black;
            }

            .thead-medications th{
                color: #626262;
                font-size: 14px;
                font-weight: 500;
            }
            .medication-tables tbody tr td{
                color:#595959;
                font-weight: 500;
                font-size: 12px;
            }

            .medication-view a{
                color: #1758FF;
                text-decoration: underline;
                font-weight: 300;
            }

            .medication-buttons .btn-primary{
                background-color: #1b538b;
            border: none;
            font-size: 14px;
        }
            
            .medical-history{
                padding: 10px 20px 10px 30px;
                background-color: #1b538b;
                border-radius: 10px;
               
                color: #fff;
            }
            .medical-history h5{
                margin: 0;
            }

            .medication-tables{
                background-color: #ecf5ff;
                padding: 20px;
            }
           

            .medical-history .input-group{
background-color: #fff;
color: #000;
width: 30%;
border-radius: 10px;
            }

            .medical-history .input-group .form-control{
                padding: 10px;
                border-radius: 10px;
                            }

         /* medication-tab close */

/* problem-tabs start */

.problem-links{
    padding: 20px 30px 20px 30px;
    background-color: #ecf5ff;
    margin-bottom: 10px;
    border-radius: 
    0px 0px 0px 10px;
}

.problem-search-feild .input-group{
    width: 100%;
}

.problem-search-feild .input-group .form-control{
    background-color:#ECF5FF ;
    border: 1px solid black;
    padding: 10px;
    border-radius: 10px;
}

.problem-content .nav-link {
    margin-right: 10px;
}

.no-arrow-dropdown:after{
    display: none
}



.accordion-button:not(.collapsed){
    color: #ffffff;
    background-color: #276bad;
}

.patient-detail-tabs .nav-tabs .nav-link{
    display: flex;
align-items: center;
justify-content: center;
}
.patient-detail-tabs .nav-tabs li .fa-times{
background-color:#ba2748;
height:16px;
width:16px;
color: white;
display: flex;
align-items: center;
justify-content: center;
margin-left: 10px;
    font-size: 12px;
    border-radius: 3px;
}

.patient-detail-tabs .nav-link{
    background-color:#c8e3ff;
}

.form-check-label{
    font-size: 12px;
}
.patient-detail-tabs .nav-tabs .nav-link.active .fa-times{
    background-color: white;color: #002548
}


.btn-primary {
    color: #fff;
    background-color: #ba2748;
    border-color: #951c37;
}

.btn-primary:hover {
    color: #fff;
    background-color: #9d1e3a;
    border-color: #951c37;
}

.problem-right-bg{
   
    padding: 20px;
}

.form-diagnosis {
    width: 90%;
}

.diagnosis-btn .btn-secondary{
background-color: #e0eefc;
color: #000;
margin-top: 6px;
border-radius: 10px;
padding: 10px;
width:130px;
font-size: 14px;
}

.btn-success{
    background-color: #307bc4;
    border: none;
   width: 115px;
   padding: 8px;
border-radius: 5px;
font-size: 14px;
}
/* problem tab close */
/*demographics tab start*/ 
.demo-bg{

    padding: 20px 30px 20px 30px;
    background-color: #ecf5ff;
    border-radius: 
    0px 0px 0px 10px;
    margin-bottom: 5px;
}


.demo-main-patient{
    background-color: #ecf5ff;
  
    border-radius: 10px;
    padding: 20px;
}


.demo-personal-info .form-control{
    
    height: 65px;
    border: none;
    background-color: #fbfbfb;
    box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19)

}

.demo-personal-info{
    background-color: #fff;
    padding: 20px 20px ;
    border-radius:10px ;
}

.demo-bg .nav-tabs .nav-link{
    background-color: transparent;
    border: 1px solid #4D4D4D;
    padding: 10px;
    margin-right:40px;
    border-radius: 10px;
    width: 150px;
    font-size: 12px;
}

.demo-bg .nav-tabs{
    border-bottom: none;
}
/*demographics tab close*/ 
 /* insurance tab start */

 .insurance-heading{
    background-color: #307bc4;
    padding: 15px 30px 15px 30px;
 }
 .insurance-heading h5{
color: white;
 }
 
 h5{
    margin: 0 !important;
 }

 .insurance-heading ul li a{
    text-decoration: underline;
    color: white;
 }

 

 .insurance-data{
    min-height: 250px;
 }

 .insurance-data ul li a{
    color: #ae4f4d;
    font-weight: 500;
    font-size: 14px;
 }

 .input-group-prepend i {
    font-size: 1rem;
}

.insurance-search .form-floating{
    width: 92%;
    border-radius: 0px 0px 10px 10px ;
}

.insurance-search .form-control{
  border: 1px solid #686868;
  
}
.insurance-search .input-group-text{
    border: 1px solid #686868;
    background-color: #ffffff;
    border-right: none;
}
.insurance-p{
    padding: 20px 30px 20px 30px;
    background-color: #ecf5ff;
}
.border-bottom {
    border-bottom: 1px solid #686868 !important;
    
}



.insurance-table thead{
    color: #686868;
}
 /* insurance tab close */
 /* Contact tab start */
.content-heading{
    border: 1px solid #686868;
    border-radius: 10px;
}
.content-heading h6 a{
font-weight: 600;
    color:#307BC4;
    font-size: 14px;

}
.contact-link li a{
    color:#FF0000!important;
padding: 20px;
font-size: 12px;
}

.accordion-item{
    font-size: 12px;
} 

.accordion-button{
    font-size: 14px;
}
.contact-feilds{
    padding: 20px;
}

 /* Contact tab Close */



/* setting-tab-start */

.setting-bg{
    background-color: #ecf5ff;
    padding: 20px 10px 20px 10px;
  border-radius:0px 30px 0px 0px;
  height: 100vh;
 
}

.nav-pills .nav-link{
    font-size: 14px;
}

.setting-bg .nav-link {
    text-align: left;
    margin-bottom: 15px;
    color: rgb(0, 0, 0);
    border-bottom: 2px solid #afafaf;
    background-color: transparent;
   
}

.map-content .form-control{

    border: none;
    border-radius: 10px;
   background-color:#f9f9f9;
   box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;}

.map-content h5{
margin: 0;

}

.map-bg{
    padding: 10px 20px 10px 20px;
    background-color: #307bc4;
    border-radius: 10px 10px 0px 0px;
}
.map-bg h5{
    color: #fff;
}
.map-border{
    border: 1px solid #000;
    border-top-color: transparent;
    border-radius: 0px 0px 10px 10px;
    padding: 10px;

}


.preview-links .nav-tabs .nav-link{
    background-color: transparent;
    border: 1px solid #4D4D4D;
    padding: 10px;
    margin-right: 15px;
    border-radius: 10px;
    width: 120px;
}

.facilty-links .nav-item{
    background-color: #a6cffa;
 
       
       margin-right: 20px;
}

.facilty-links .nav-tabs .nav-link.active {
    background-color: #1b538b;
    color: #fff;
}
   

.facilty-links .nav-tabs .nav-link:hover{
    border-color: transparent;
}


.myprofile-bg{
  padding: 20px;
  background-color: #ecf5ff;
  border-radius: 10px;
}

.myprofile-bg h5{
    font-family: 600;
    color: #000;
    margin-bottom: 20px;
}

.electric-forms{
    border: 1px solid #cbcbcb;
    padding: 20px;
}

.electric-btn .btn-electric {
border: 1px solid #686868;
border-radius: 10px;
width: 130px;
}

.security-content{

    padding: 20px;
    background-color: #ecf5ff;
    border-radius: 10px;

}

.security-content h5{
font-weight: 600;
}

.password-forms{

    padding: 20px;
    background-color: #ecf5ff;
    border-radius: 10px;
}

.password-forms h5{
    font-weight: 600;
}

.btn-close {
   
    border: 0;
    border-radius: .25rem;
     opacity: 1; 
}


.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #1b538b;
}

.contact-bg-sec{
    background-color: #ecf5ff;
    padding: 20px 20px 20px 20px;
}

.active-bg-links{
    background-color: #ecf5ff;
    padding: 20px 10px 20px 10px;
}

.map-bg-sec{
    background-color: #ecf5ff;
    padding: 20px;
}

.floor-1-bg{
    background-color: #ecf5ff;
    padding: 20px;
}

.bg-contact{
    background-color: #307bc4;
    border-radius: 8px 8px 0px 0px;
    z-index: 9999;
    color: #fff;
}